import "./CardEspacoFloor.css"

function CardEspacoFloor() {
    return (
        <>
            <div className="card-container">
                <div className='text-block'>
                    <h1 className='piso-name'>Acessório para Instalação de Deck</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>3</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>3</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                </div>
                <div className='text-block'>
                    <h1 className='piso-name'>Acessório para Instalação de Piso</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>3</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>3</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                </div>



                <div className='text-block'>
                    <h1 className='piso-name'>Acessório para Instalação de Rodapé</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>3</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>3</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                </div>



                <div className='text-block'>
                    <h1 className='piso-name'>Deck</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>5</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>5</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                </div>


                <div className='text-block'>
                    <h1 className='piso-name'>Material de Comunicação</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>3</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>3</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                </div>


                <div className='text-block'>
                    <h1 className='piso-name'>Piso de Madeira - Aquawood</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>20</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>5</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                </div>

                <div className='text-block'>
                    <h1 className='piso-name'>Piso de Madeira - Master e Deluxe</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>10</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>5</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                </div>


                <div className='text-block'>
                    <h1 className='piso-name'>Piso Vinílico - Loose Lay</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>25</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>15</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                </div>

                <div className='text-block'>
                    <h1 className='piso-name'>Piso Vinílico - Office</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>20</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>15</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                </div>

                <div className='text-block'>
                    <h1 className='piso-name'>Piso Vinílico - Royal</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>12</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <span className='garantia-meses'>Sem garantia</span>
                        </div>
                    </div>
                </div>

                <div className='text-block'>
                    <h1 className='piso-name'>Piso Vinílico - Soft</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>10</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            {/* <h1 className='garantia-numero'></h1> */}
                            <span className='garantia-meses'>Sem Garantia</span>
                        </div>
                    </div>
                </div>


                <div className='text-block'>
                    <h1 className='piso-name'>Piso Vinílico - Solid Plank</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>15</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>7</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                </div>

                <div className='text-block'>
                    <h1 className='piso-name'>Rodapé de Poliestireno</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>5</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>5</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                </div>


                <div className='text-block'>
                    <h1 className='piso-name'>Rodapé Invertido</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>3</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>3</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                </div>




                <div className='text-block'>
                    <h1 className='piso-name'>Piso Laminado - Aquapro</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>30</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>5</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                </div>



                <div className='text-block'>
                    <h1 className='piso-name'>Piso Laminado - New Loc Comfort</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>15</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <span className='garantia-meses'>Sem garantia</span>
                        </div>
                    </div>
                </div>

                <div className='text-block'>
                    <h1 className='piso-name'>Rodapé de MDF</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>3</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>3</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                </div>

                <div className='text-block'>
                    <h1 className='piso-name'>Piso Vinílico - Spot Piso</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>10</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            {/* <h1 className='garantia-numero'>3</h1> */}
                            <span className='garantia-meses'>Sem garantia</span>
                        </div>
                    </div>
                </div>


                <div className='text-block'>
                    <h1 className='piso-name'>Piso Vinílico - Work</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>10</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>5</h1>
                            <span className='garantia-meses'>anos</span>
                        </div>
                    </div>
                </div>


                <div className='text-block'>
                    <h1 className='piso-name'>Rodapé de PVC</h1>
                    <div className='garantia'>
                        <span>Período de Garantia Residencial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>3</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                    <hr />
                    <div className='garantia'>
                        <span>Período de Garantia Comercial</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>3</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default CardEspacoFloor;
