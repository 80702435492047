function CardEspacoForro() {
    return (
        <div className="card-container">

            <div className='text-block'>
                <h1 className='piso-name'>Acessório para Instalação de Forro</h1>
                <div className='garantia'>
                    <span>Período de Garantia Residencial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
                <hr />
                <div className='garantia'>
                    <span>Período de Garantia Comercial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
            </div>

            <div className='text-block'>
                <h1 className='piso-name'>Alçapão</h1>
                <div className='garantia'>
                    <span>Período de Garantia Residencial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
                <hr />
                <div className='garantia'>
                    <span>Período de Garantia Comercial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
            </div>


            <div className='text-block'>
                <h1 className='piso-name'>Forro de Alumínio</h1>
                <div className='garantia'>
                    <span>Período de Garantia Residencial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
                <hr />
                <div className='garantia'>
                    <span>Período de Garantia Comercial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
            </div>


            <div className='text-block'>
                <h1 className='piso-name'>Forro de Gesso</h1>
                <div className='garantia'>
                    <span>Período de Garantia Residencial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>2</h1>
                        <span className='garantia-meses'>anos</span>
                    </div>
                </div>
                <hr />
                <div className='garantia'>
                    <span>Período de Garantia Comercial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>2</h1>
                        <span className='garantia-meses'>anos</span>
                    </div>
                </div>
            </div>


            <div className='text-block'>
                <h1 className='piso-name'>Forro Vinílico e PVC </h1>
                <div className='garantia'>
                    <span>Período de Garantia Residencial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>1</h1>
                        <span className='garantia-meses'>ano</span>
                    </div>
                </div>
                <hr />
                <div className='garantia'>
                    <span>Período de Garantia Comercial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>1</h1>
                        <span className='garantia-meses'>ano</span>
                    </div>
                </div>
            </div>

            <div className='text-block'>
                <h1 className='piso-name'>Nuvem Acústica</h1>
                <div className='garantia'>
                    <span>Período de Garantia Residencial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
                <hr />
                <div className='garantia'>
                    <span>Período de Garantia Comercial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardEspacoForro;